module.exports = [{
      plugin: require('/home/agmdev/repos/github/back/gatsby-agm-dev-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"withWebp":true,"ignoreFileExtensions":[]},
    },{
      plugin: require('/home/agmdev/repos/github/back/gatsby-agm-dev-blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/agmdev/repos/github/back/gatsby-agm-dev-blog/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-61148321-4"],"pluginConfig":{"head":true}},
    },{
      plugin: require('/home/agmdev/repos/github/back/gatsby-agm-dev-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/agmdev/repos/github/back/gatsby-agm-dev-blog/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/agmdev/repos/github/back/gatsby-agm-dev-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
